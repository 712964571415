<template>
  <div class="d-flex align-center border-bottom">
    <v-icon
      color="black"
      class="pointer align-self-stretch border-right no-highlight"
      small
      @click="moveToProduct('prev')">
      mdi-chevron-left
    </v-icon>
    <v-autocomplete
      ref="rows-switcher"
      :filter="filterProduct"
      :items="rows"
      :menu-props="{ maxHeight: 500 }"
      :search-input.sync="search"
      :value="value"
      autocomplete="off"
      class="py-2 v-autocomplete__rows-switcher"
      item-value="id"
      flat
      hide-details
      return-object
      solo
      @blur="search = null"
      @change="search = null"
      @input="$emit('input', $event)">
      <template #append>
        <v-icon
          color="black"
          class="pointer">
          mdi-chevron-down-circle
        </v-icon>
      </template>

      <template #append-outer>
        <slot name="productRef" />
      </template>

      <template #item="{ item, $on, $attrs }">
        <span
          v-bind="$attrs"
          v-on="$on">
          <ProductSwitcherItem :item="truncatedItem(item)" />
        </span>
      </template>

      <template #selection="{ item }">
        <ProductSwitcherItem
          v-if="!search || search === ''"
          :item="truncatedItem(item)" />
      </template>
    </v-autocomplete>
    <v-icon
      color="black"
      class="pointer align-self-stretch border-left no-highlight"
      small
      @click="moveToProduct('next')">
      mdi-chevron-right
    </v-icon>
  </div>
</template>

<script>
import ProductSwitcherItem from './ProductSwitcherItem';

const MAX_CODE_LENGTH = 45;

export default {
  name: 'ProductSwitcher',
  components: {
    ProductSwitcherItem,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: '',
  }),
  methods: {
    filterProduct(item, queryText) {
      const { model = '', type = '' } = item;
      return model.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        || type.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    moveToProduct(direction) {
      const { rows, value } = this;
      let length = rows.length;
      let index = rows.findIndex(el => el.id === value.id);
      let nextProduct;

      if (index === -1) return;

      if (direction === 'next') {
        nextProduct = ((index + 1) === length) ? rows[0] : rows[index + 1];
      } else {
        nextProduct = ((index - 1) < 0) ? rows[length - 1] : rows[index - 1];
      }

      this.$emit('input', nextProduct);
    },
    truncatedItem(item) {
      const code = item?.productCode;
      if (!code || typeof(code) !== 'string' || code.length < MAX_CODE_LENGTH) return item;

      return {
        ...item,
        productCode: code.substring(0, 45) + '...',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.border {
  &-left {
    border-left: 1px solid var(--v-darkGrey-base);
  }
  &-right {
    border-right: 1px solid var(--v-darkGrey-base);
  }
  &-bottom {
    border-bottom: 1px solid var(--v-darkGrey-base);
  }
}

.v-autocomplete__rows-switcher {
  align-items: center;

  ::v-deep .v-input__slot {
    padding-right: 0 !important;
  }
  ::v-deep .v-input__append-outer {
    margin: 0;
  }
}

::v-deep .v-list-item {
  min-height: 4rem;
  margin-bottom: 1.25rem;
}
::v-deep .v-list-item:last-child {
  margin-bottom: 0;
}
</style>